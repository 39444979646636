export class Parametro {
    static readonly DETALLES_PRIVADOS: string = 'DETALLES_PRIVADOS';
    static readonly SII_ACTIVADO: string = 'SII_ACTIVADO';
    static readonly CONCEPTO_ACTIVADO = 'CONCEPTO_ACTIVADO';
    static readonly TIPO_AGENDAMIENTO: string = 'TIPO_AGENDAMIENTO';
    static readonly DURACION_REUNION: string = 'DURACION_REUNION';
    static readonly DESCARGA_AUTOMATICA: string = 'DESCARGA_AUTOMATICA';
    static readonly FORMATO_PRECIO_REVISION: string = 'FORMATO_PRECIO_REVISION';
    static readonly FORMATO_TAMANO_REVISION: string = 'FORMATO_TAMANO_REVISION';
    static readonly IVA: string = 'IVA';
    static readonly UPDATE_PRECIO_COMPRA_EXACTO: string = 'UPDATE_PRECIO_COMPRA_EXACTO';
    static readonly PERMITIR_PRECIOS_PDF_CLIENTE_APP: string = 'PERMITIR_PRECIOS_PDF_CLIENTE_APP';
    static readonly SERVICIOS_EN_VENTA: string = 'SERVICIOS_EN_VENTA';
    static readonly OPERADOR_EDITA_VENTAS: string = 'OPERADOR_EDITA_VENTAS';
    static readonly ASIGNA_FOLIO_VACIO: string = 'ASIGNA_FOLIO_VACIO';
    static readonly RECORDATORIO_DEUDAS_CLIENTE: string = 'RECORDATORIO_DEUDAS_CLIENTE';
    static readonly METODO_PAGO_POR_DEFECTO_OT: string = 'METODO_PAGO_POR_DEFECTO_OT';
    static readonly CAJA_OBLIGATORIA: string = 'CAJA_OBLIGATORIA';
    static readonly OT_COMPLETADA_CAJA: string = 'OT_COMPLETADA_CAJA';
    static readonly OT_COMPLETADA_REPORTE: string = 'OT_COMPLETADA_REPORTE';
    static readonly RESUMEN_SEMANAL_ACTIVADO: string = 'RESUMEN_SEMANAL_ACTIVADO';

    static readonly KILOMETRAJE_REVISION_OPCIONAL: string = 'KILOMETRAJE_REVISION_OPCIONAL';
    static readonly PRECIO_PRODUCTO_OPCIONAL: string = 'PRECIO_PRODUCTO_OPCIONAL';
    static readonly RECIBIR_COTIZACIONES: string = 'RECIBIR_COTIZACIONES';
    static readonly MAXIMO_ARCHIVOS_OT: string = 'MAXIMO_ARCHIVOS_OT';
    static readonly OPERADOR_CREA_PRODUCTOS_EN_REVISION: string = 'OPERADOR_CREA_PRODUCTOS_EN_REVISION';

    static readonly TIPO_AGENDAMIENTO_DEFAULT: string = '2';
    static readonly DURACION_REUNION_DEFAULT: string = '30';
    static readonly FORMATO_TAMANO_REVISION_DEFAULT: string = '1';
    static readonly FORMATO_PRECIO_REVISION_DEFAULT: string = '1';
    static readonly DESCARGA_AUTOMATICA_DEFAULT: string = '1';
    static readonly PERMITIR_PRECIOS_PDF_CLIENTE_APP_DEFAULT: string = '1';
    static readonly OPERADOR_EDITA_VENTAS_DEFAULT: boolean = true;
    static readonly ASIGNA_FOLIO_VACIO_DEFAULT: boolean = false;
    static readonly RECORDATORIO_DEUDAS_CLIENTE_DEFAULT: string = '7';
    static readonly METODO_PAGO_POR_DEFECTO_OT_DEFAULT: string = '1';
    static readonly KILOMETRAJE_REVISION_OPCIONAL_DEFAULT: string = '0';
    static readonly PRECIO_PRODUCTO_OPCIONAL_DEFAULT: string = '0';
    static readonly MAXIMO_ARCHIVOS_OT_DEFAULT: string = '5';
    static readonly OPERADOR_CREA_PRODUCTOS_EN_REVISION_DEFAULT: string = '0';
}