import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkdownService } from 'ngx-markdown';
import { SesionService } from '../sesion/sesion.service';
import { FormatterService } from '../formatter/formatter.service';
import { SentryService } from '../sentry/sentry.service';
import { SocketService } from '../socket/socket.service';
import { SuscripcionesService } from 'src/app/components/suscripciones/suscripciones.service';
import { AlertService, LoadingService } from 'ngx-kuv-tools';

@Component({
  selector: 'app-subscription-tos',
  templateUrl: './subscription-tos.component.html',
  styleUrls: ['./subscription-tos.component.scss']
})
export class SubscriptionTosComponent implements OnInit {

  empresaID = 0;
  paisID = 46;
  periodo = '';
  plan = '';
  montoParcial = 0;
  url = '';
  email: string = '';
  data: any;

  md: string = '';

  get planFormateado(): string {
    switch (this.plan) {
      case 'FILTRO_X': return 'Filtro X';
      case 'ACELERA': return 'Acelera+';
      case 'TURBO': return 'Turbo';
      default: return 'Filtro X';
    }
  }

  get intervaloPlanFormateado(): string {
    switch (this.periodo) {
      case 'DIARIO': return 'Diario';
      case 'MENSUAL': return 'Mensual';
      case 'ANUAL': return 'Anual';
      default: return 'Mensual';
    }
  }

  get esPaisChile(): boolean {
    return this.sesionService.esPaisChile(this.paisID ? this.paisID : this.sesionService.usuario.pais_id);
  }

  get currency(): string {
    return this.esPaisChile ? '$' : 'USD $';
  }

  constructor(
    private http: HttpClient,
    private mdService: MarkdownService,
    private router: Router,
    private route: ActivatedRoute,
    private sesionService: SesionService,
    public formatter: FormatterService,
    private sentryService: SentryService,
    private socketService: SocketService,
    private service: SuscripcionesService,
    private loading: LoadingService,
    private alerts: AlertService,
  ) { }

  ngOnInit(): void {
    this.loadToS();
    this.readParams();
  }

  readParams(): void {
    this.route.queryParams.subscribe(params => {
      if (params['payload']) {
        this.data = JSON.parse(atob(params['payload']));
        if (this.data.empresaID) this.empresaID = this.data.empresaID;
        if (this.data.paisID) this.paisID = this.data.paisID;
        if (this.data.tipo) this.periodo = this.data.tipo;
        if (this.data.plan) this.plan = this.data.plan;
        if (this.data.montoParcial) this.montoParcial = this.data.montoParcial;
        if (this.data.email) this.email = this.data.email;
        if (this.data.url) this.url = this.data.url;
      }
    });
  }

  formatPrice(num: number): string {
    if (this.esPaisChile) return this.formatter.numberFormat(num);
    return this.formatter.numberFormat(num, 2, 2, '', 'en-US');
  }

  async loadToS() {
    this.http.get(`/assets/tos/tos.md`,
      { responseType: 'text' }).subscribe({
        next: (res) => {
          this.md = this.mdService.compile(res);
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  abrirEnlacePago(): void {
    this.socketService.startConnection(null);
    if (this.url) {
      window.open(this.url, "_blank");
      return;
    }
    this.loading.show();
    const data = {
      empresa_id: this.empresaID,
      intervalo: this.periodo,
      plan: this.plan,
      productos: ['LUBRIKUV'],
      monto_parcial: this.montoParcial,
    };
    this.service.getEnlacePagoEnlaceExterno(this.empresaID ? this.empresaID : this.sesionService.user.empresa_id, this.paisID, data).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.url = res.url;
        window.open(this.url, "_blank");
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert(err.error?.msg ?? "Error al obtener el enlace de Pago.", "danger");
        this.sentryService.captureException(err.error);
      }
    });
  }

  cancelar(): void {
    this.router.navigate(['/login']);
  }

  aceptar(): void {
    this.abrirEnlacePago();
  }
}
